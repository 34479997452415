/* homepage */
.text-block-3 p,
.text-block-3 td,
.text-block-3 span,
.text-block-3 li {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	color: inherit;
}

.text-block-3 p:last-child {
	margin-bottom: 0px;
}

.text-block-3 a {
	counter-reset: inherit;
}

.about-us-heading-wrapper .h2-heading {
	margin-bottom: 0.5rem;
}

.slide {
	background-position: 0px 0px, 50% 55%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide .heading-3,
.slide h2 {
	max-width: 800px;
	margin-top: 0px;
	margin-bottom: 0.5rem;
	padding: 0rem 1rem;
	border-radius: 3px;
	background-color: transparent;
	color: #fff;
	font-size: 2.75rem;
	line-height: 2.5rem;
	font-weight: 700;
	text-align: center;
	text-shadow: 1px 1px 6px #474747;
	letter-spacing: 0;
}

.slide .text-block-3 {
	max-width: 700px;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1rem 0rem;
	border-radius: 3px;
	background-color: transparent;
	color: #fff;
	font-size: 1.1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: none;
	text-shadow: 1px 1px 6px #474747;
	padding-top: 0px;
}

.copyright-paragraph h1 {
	display: inline;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	margin: 0px;
	font-size: inherit;
}

@media screen and (max-width: 991px) {
	.slide .heading-3,
	.slide h2 {
		max-width: 650px;
		font-size: 2.75rem;
		line-height: 2.5rem;
	}

	.slide .text-block-3 {
		font-size: 1rem;
	}

	.calendar-wrap,
	.cb-feature.events {
		margin-bottom: 0px;
		border-bottom: 0px;
	}

	.about-cb-content .paragraph p:first-child {
		margin-top: 0px;
	}

	#news_module.homepage_contentbox .view-all-link {
		position: absolute;
		right: 0;
		top: -55px;
	}

	#events_module.homepage_contentbox .footer {
		position: absolute;
		right: 0;
		top: -55px;
	}
}

@media screen and (max-width: 767px) {
	.slide .heading-3,
	.slide h2 {
		max-width: 500px;
		font-size: 2.25rem;
		line-height: 1.9rem;
	}

	.slide .text-block-3 {
		font-size: 1rem;
	}

	#news_module.homepage_contentbox .view-all-link {
		position: static;
		right: 0;
		top: 0;
		order: 0;
		display: inline-block;
		margin: 0 auto;
		margin-bottom: 2rem;
	}

	#events_module.homepage_contentbox .footer {
		position: static;
		right: 0;
		top: 0;
		order: 0;
		display: block;
		margin: 0 auto;
		margin-bottom: 2rem;
		text-align: center;
	}
}

@media screen and (max-width: 479px) {
	.slide .heading-3,
	.slide h2 {
		max-width: none;
		padding-right: 2.25rem;
		padding-left: 2.25rem;
		font-size: 2.25rem;
		line-height: 1.65rem;
		text-align: center;
	}

	.slide .text-block-3 {
		font-size: 0.9rem;
		line-height: 1.25rem;
		text-align: center;
	}
}

/* custom landing pages */
.grid-container .content-box.center-content .content-box-title h2,
.grid-container .content-box.center-content-no-title .content-box-title h2 {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
	margin-bottom: 1rem;
	text-transform: none;
	margin-top: 0px;
}

@media screen and (max-width: 479px) {
	.grid-container .content-box.center-content .content-box-title h2,
	.grid-container .content-box.center-content-no-title .content-box-title h2 {
		font-size: 1.75rem;
		line-height: 1.9rem;
		text-align: center;
		margin-bottom: 0;
	}
}
